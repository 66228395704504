<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:parent="{ item }">
        <span class="text-capitalize">{{ item.parent && item.parent.group_name ? item.parent.group_name : 'N/A'
        }}</span>
      </template>

      <template v-slot:vehicles="{ item }">
        <div>
          <span>
            <!-- Display vehicle names if available, otherwise show 'N/A' -->
            {{ item.vehicles && item.vehicles.length > 0
              ? item.vehicles.map(vehicle => vehicle.vehicle_name).join(', ')
              : 'N/A' }}
          </span>
        </div>
      </template>
      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.parent_id"
            filled
            dense
            clearable
            ::loading="getIsLoadingGroup"
            :items="groupsList"
            item-value="id"
            item-text="group_name"
            :label="$t('parent')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <template #moreFilters>
        <div>
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('vehicle-groups.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('vehicle-groups.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-group
            v-if="isCreate"
            :options="options"
            @change="fetchData"
          />
          <edit-group
            v-if="(isEdit || isView) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :group-data="selectedRow"
            @change="fetchData"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateGroup: () => import("./dialogs/CreateGroup.vue"),
    EditGroup: () => import("./dialogs/EditGroup.vue"),
    ConfirmationModal: () => import("@/components/Modals/ConfirmationModal.vue"),
    refreshButton: () => import("../../../components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      customFilters: {},
      filters: {},
      subFilters: {},
      showCreateGroupModal: false,
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("group_name"),
          align: "left",
          sortable: false,
          value: "group_name",
        },
        {
          text: this.$t("parent"),
          align: "left",
          sortable: false,
          value: "parent"
        },
        {
          text: this.$t("vehicles"),
          align: "left",
          sortable: false,
          value: "vehicles",
        },
        {
          text: this.$t("actions"),
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
      permissions: {
        view: "vehicle-groups.view",
        create: "vehicle-groups.create",
        edit: "vehicle-groups.edit",
        delete: "vehicle-groups.delete",
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicles/getGroupListingLoader",
      meta: "vehicles/geGroupMeta",
      list: "vehicles/getGroupListing",
      getIsLoadingGroup: "vehicles/getIsLoadingGroup",
      groupsList: "vehicles/getGroupsList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('vehicles/setGroupLoadingValue', true)
  },
  beforeDestroy() {
    let paramSet = {}
    paramSet = { ...this.options, ...this.customFilters }
    const params = {
      name: "vehicleGroup",
      filters: paramSet,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    groupListing() {
      this.$store.dispatch("vehicles/groupDropDown", {
        tenant_id: this.authUser.tenant_id,
        product: this.authUser.user_nature
      });
    },
    async fetchData() {
      this.groupListing()
      this.options.tenant_id = (this.authUser.independent_fleet_user_nature) ? this.authUser.tenant_id : this.authUser.parent_tenant_id;
      let params = { ...this.options, ...this.customFilters }
      params.child_group_detail = 1
      this.options.page = 1;
      await this.$store.dispatch("vehicles/vehicleGroupListing", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(mdMake) {
      try {
        await this.$store.dispatch("vehicles/destroyGroup", mdMake);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData()
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>